




























































import { InlineLoader, SortDir } from '@/shared';
import StatusIndicatorIcon from '@/shared/components/StatusIndicatorIcon.vue';
import sink from '@/sink';
import { defineComponent, onMounted } from '@vue/composition-api';
import {
  FetchPatientCompletedChats,
  PatientCompletedChatsPageUpdated,
  PatientCompletedChatsResponsesRequested,
  PatientCompletedChatsRowExpanded,
  PatientCompletedChatsTableSorted,
} from './+state/events';
import { PatientDetailsCompletedChatsSortedCol } from './models/sorted-col.interface';

export default defineComponent({
  components: {
    InlineLoader,
    StatusIndicatorIcon,
  },
  setup() {
    onMounted(sink.lazyBroadcast(FetchPatientCompletedChats()));

    const sortedCol = sink.select('patient.details.completed-chats.sorted-col');
    const sortDir = sink.select('patient.details.completed-chats.sort-dir');

    const updateSort = (sortInfo: {
      col: PatientDetailsCompletedChatsSortedCol;
      dir: SortDir;
    }) => sink.broadcast(PatientCompletedChatsTableSorted(sortInfo));

    const onChangeSortedColumn = (
      col: PatientDetailsCompletedChatsSortedCol,
    ) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortedCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    const updatePage = page => {
      sink.broadcast(PatientCompletedChatsPageUpdated({ page }));
    };

    const onItemExpanded = ({ item, value: isExpanded }) => {
      sink.broadcast(PatientCompletedChatsRowExpanded({ item, isExpanded }));

      if (isExpanded) {
        sink.broadcast(
          PatientCompletedChatsResponsesRequested({ id: item.checkupId }),
        );
      }
    };

    return {
      completedChats: sink.select('patient.details.completed-chats'),
      loading: sink.select('patient.details.completed-chats.loading'),
      pageNumber: sink.select('patient.details.completed-chats.page.number'),
      count: sink.select('patient.details.completed-chats.count'),
      expandedRows: sink.select(
        'patient.details.completed-chats.expanded-rows',
      ),
      tableMessage: sink.select(
        'patient.details.completed-chats.table-message',
      ),
      sortedCol,
      sortDir,
      headers: [
        { value: 'chatDate', text: 'Chat Date', sortable: true },
        { value: 'programModule', text: 'Program Module', sortable: false },
        { value: 'chatStatus', text: 'Chat Status', sortable: true },
        { value: 'data-table-expand', text: '', sortable: false },
      ],
      subTableHeaders: [
        { value: 'question', text: 'Question', sortable: false },
        { value: 'response', text: 'Response', sortable: false },
        { value: 'responseStatus', text: 'Response Status', sortable: false },
      ],
      onChangeSortedColumn,
      onChangeSortDirection,
      updatePage,
      onItemExpanded,
    };
  },
});
